import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import List from "../../components/List";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DeviceListItem } from "../../models/device-list-item";
import { LiftOptionsMenu } from "./LiftOptionsMenu";
import { CustomerDevice } from "../../services/customers-api";
import { TenantDevice } from "../../services/tenants-api";
import { TenantListItem } from "models/tenant-list-item";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import "./LiftList.css";

export interface LiftListProps {
  devices: DeviceListItem[];
  tenant: TenantListItem;
  selectedDevice: CustomerDevice | undefined;
  setSelectedDevice(selectedDevice: CustomerDevice | undefined): void;
  setSelectedTenantDevice(selectedTenantDevice: TenantDevice | undefined): void;
  setSelectedResetDevice(selectedResetDevice: TenantDevice): void;
}

export const LiftList = ({
  devices,
  tenant,
  selectedDevice,
  setSelectedDevice,
  setSelectedTenantDevice,
  setSelectedResetDevice,
}: LiftListProps) => {
  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper<DeviceListItem>();
  const { dispatchCliftState } = useCliftContext();

  const getSubscriptionState = (value: string) => {
    if (value === "ACTIVE") {
      return (
        <span className="bullet-active">{t("active", { ns: "lift" })}</span>
      );
    } else if (value === "SUSPENDED") {
      return (
        <span className="bullet-paused">{t("suspended", { ns: "lift" })}</span>
      );
    } else {
      return (
        <span className="bullet-inactive">
          {t("unregistered", { ns: "lift" })}
        </span>
      );
    }
  };

  const columns: ColumnDef<DeviceListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("serialNumber", {
        header: () => t("serial_number", { ns: "lift" }),
        cell: (info) => (
          <Link
            to={info.row.original.id.toString()}
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.SetFromOriginalPage,
                fromOriginalPage: "/lifts",
              });
            }}
          >
            {info.row.original.serialNumber}
          </Link>
        ),
      }),
      columnHelper.accessor("model", {
        header: () => t("model", { ns: "lift" }),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("nickname", {
        header: () => t("nickname", { ns: "lift" }),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("subscriptionState", {
        header: () => t("state", { ns: "lift" }),
        cell: (info) => getSubscriptionState(info.getValue()),
      }),

      columnHelper.accessor("customerName", {
        header: () => t("customer", { ns: "lift" }),
        cell: (info) => info.getValue(),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          const device = devices.find((t) => t.id === info.row.original.id);
          return device ? (
            <LiftOptionsMenu
              customerID={device.customerId}
              deviceID={device.id}
              tenantID={tenant.id}
              selectedDevice={selectedDevice}
              setSelectedDevice={setSelectedDevice}
              setSelectedTenantDevice={setSelectedTenantDevice}
              setSelectedResetDevice={setSelectedResetDevice}
            />
          ) : undefined;
        },
      }),
    ],
    [i18n.language]
  );

  return (
    <List<DeviceListItem> columns={columns} data={devices} showSearch={true} />
  );
};
