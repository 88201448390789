import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import List from "../../components/List";
import { EmployeeListItem } from "../../models/user";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { EditEmployee } from "./EditEmployee";
import { EmployeeOptionsMenu } from "./EmployeeOptionsMenu";
import {
  StannahRole,
  EmployeeListViewAllowedRoles,
  EmployeeListEditAllowedRoles,
} from "../../models/role";

export interface EmployeeListProps {
  employees: EmployeeListItem[];
  setSelectedUserID(userId: number): void;
}

export const EmployeeList = ({
  employees,
  setSelectedUserID,
}: EmployeeListProps) => {
  const { t, i18n } = useTranslation();
  const [loading] = useState(false);
  const { dispatchCliftState } = useCliftContext();

  const columnHelper = createColumnHelper<EmployeeListItem>();

  const getLocalizedRole = (userRole: string): string => {
    if (i18n.exists(userRole, { ns: "role" })) {
      return i18n.t(userRole as StannahRole, { ns: "role" });
    }
    return i18n.t("NO_ROLE", { ns: "role" });
  };

  const columns: ColumnDef<EmployeeListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => t("name", { ns: "translation" }),
        cell: (info) => (
          <div
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.SetSidePanelContent,
                content: (
                  <EditEmployee userId={info.row.original.userAccountId} />
                ),
              });
            }}
          >
            {info.row.original.name}
          </div>
        ),
      }),
      columnHelper.accessor("email", {
        header: () => t("email", { ns: "employee" }),
        cell: (info) => (
          <div
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.SetSidePanelContent,
                content: (
                  <EditEmployee userId={info.row.original.userAccountId} />
                ),
              });
            }}
          >
            {info.row.original.email}
          </div>
        ),
      }),
      columnHelper.accessor("role", {
        header: () => t("role", { ns: "employee" }),
        cell: (info) =>
          isRoleForAction(EmployeeListViewAllowedRoles) &&
          getLocalizedRole(info.getValue()),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          return (
            isRoleForAction(EmployeeListEditAllowedRoles) && (
              <EmployeeOptionsMenu
                userID={info.row.original.userAccountId}
                setSelectedUserID={setSelectedUserID}
              />
            )
          );
        },
      }),
    ],
    [i18n.language]
  );

  if (!loading) {
    return (
      <List<EmployeeListItem>
        columns={columns}
        data={employees}
        showSearch={true}
      />
    );
  } else {
    return <progress></progress>;
  }
};
