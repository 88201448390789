import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { getErrorCode } from "../../services/clift-api-errors";
import { addDevicesToTenant } from "../../services/tenants-api";
import { DeviceRegistrationItem } from "../../models/device";
import SideModal from "../../components/SideModal";
import { AddLiftsForm } from "./AddLiftsForm";

export interface AddTenantLiftsProps {
  tenantID: number;
}

export const AddTenantLifts = ({ tenantID }: AddTenantLiftsProps) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
    setError(undefined);
  };

  const onAddLifts = (devices: DeviceRegistrationItem[]) => {
    addDevicesToTenant(tenantID, devices)
      .then(() => {
        closeSidePanel();
      })
      .catch((err) => {
        setError(getErrorCode(err) as string);
      });
  };

  return (
    <SideModal
      title={t("add_lifts_title", { ns: "lift" })}
      onClose={closeSidePanel}
    >
      <AddLiftsForm
        error={error}
        initialDevices={[]}
        onSubmit={onAddLifts}
        onClose={closeSidePanel}
        omitNickName={true}
      />
    </SideModal>
  );
};
