import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled, CardGroup } from "../../../components/Card";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { isRoleForAction } from "../../../hooks/useRoles";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { Device } from "../../../models/device";
import { LiftSettingsAllowedRoles } from "../../../models/role";

export interface DeviceSettingsCardProps {
  device: Device;
}

export const DeviceSettingsCard = ({ device }: DeviceSettingsCardProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();

  const handleEdit = () => {
    if (!cliftState.currentTenant) return;

    dispatchCliftState({
      type: CliftReducerAction.EditDeviceSettings,
      tenantID: cliftState.currentTenant.id,
      device,
    });
  };
  const { t } = useTranslation();

  return (
    <Card
      title={t("device_settings_title", { ns: "lift" })}
      editAllowed={isRoleForAction(LiftSettingsAllowedRoles)}
      onEdit={handleEdit}
    >
      <CardDetailTitled
        title={t("subscription_state", { ns: "lift" })}
        value={device.deviceSettings?.subscriptionState.toString()}
      />
      <CardDetailTitled
        title={t("subscription_validity", { ns: "lift" })}
        value={
          device.deviceSettings?.subscriptionStartDate?.toString() +
          " - " +
          device.deviceSettings?.subscriptionEndDate?.toString()
        }
      />

      <CardDetailTitled
        title={t("device_ui_language", { ns: "lift" })}
        value={device.deviceSettings?.deviceUiLanguage.toString()}
      />
      <CardDetailTitled
        title={t("update_interval", { ns: "lift" })}
        value={device.deviceSettings?.updateInterval?.toString()}
      />

      {/** OTA download  */}
      <CardGroup title={t("ota_download_settings_title", { ns: "lift" })}>
        <CardDetailTitled
          title={t("ota_download_url", { ns: "lift" })}
          value={device.deviceSettings?.otaDownloadSettings?.url}
        />
        <CardDetailTitled
          title={t("ota_download_token", { ns: "lift" })}
          value={device.deviceSettings?.otaDownloadSettings?.token}
        />
        <CardDetailTitled
          title={t("ota_download_controller_id", { ns: "lift" })}
          value={device.deviceSettings?.otaDownloadSettings?.controllerId}
        />
      </CardGroup>

      {/** logs download  */}
      <CardGroup title={t("logs_download_settings_title", { ns: "lift" })}>
        <CardDetailTitled
          title={t("logs_download_url", { ns: "lift" })}
          value={device.deviceSettings?.logsDownloadSettings?.url}
        />
        <CardDetailTitled
          title={t("logs_download_token", { ns: "lift" })}
          value={device.deviceSettings?.logsDownloadSettings?.token}
        />
        <CardDetailTitled
          title={t("logs_download_token_valid_for", { ns: "lift" })}
          value={device.deviceSettings?.logsDownloadSettings?.tokenValidFor}
        />
      </CardGroup>

      {/** commissioning data download  */}
      <CardGroup
        title={t("commissioning_data_download_settings_title", { ns: "lift" })}
      >
        <CardDetailTitled
          title={t("commissioning_data_download_url", { ns: "lift" })}
          value={device.deviceSettings?.commissioningDataDownloadSettings?.url}
        />
        <CardDetailTitled
          title={t("commissioning_data_download_token", { ns: "lift" })}
          value={
            device.deviceSettings?.commissioningDataDownloadSettings?.token
          }
        />
        <CardDetailTitled
          title={t("commissioning_data_download_token_valid_for", {
            ns: "lift",
          })}
          value={
            device.deviceSettings?.commissioningDataDownloadSettings
              ?.tokenValidFor
          }
        />
      </CardGroup>

      <CardDetailTitled
        title={t("device_factory_reset_command", { ns: "lift" })}
        value={device.deviceSettings?.factoryResetCommand}
      />
      <CardDetailTitled
        title={t("device_download_logs_command", { ns: "lift" })}
        value={device.deviceSettings?.downloadLogsCommand}
      />
      <CardDetailTitled
        title={t("device_download_commissioning_data_command", { ns: "lift" })}
        value={device.deviceSettings?.downloadCommissioningDataCommand}
      />
    </Card>
  );
};
