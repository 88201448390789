import { useTranslation } from "react-i18next";
import FormField from "../../../components/FormField";
import {
  Device,
  DeviceSettings,
  DeviceUILanguage,
  SubscriptionState,
} from "../../../models/device";
import { useEffect, useState } from "react";
import { SideModalButtonBar } from "../../../components/SideModalButtonBar";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { ConfirmDialog } from "../../../components/ConfirmDialog";
import { getErrorCode } from "../../../services/clift-api-errors";
import {
  editDeviceSettings,
  EditDeviceSettingsRequest,
} from "../../../services/device-settings-api";
import SideModal from "../../../components/SideModal";
import "./SipSettingsForm.css";
import { Picker, Option } from "../../../components/Picker";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { DateTimePicker } from "react-datetime-picker";

type ValuePiece = Date | null;

const DEFAULT_SETTINGS = {
  id: 0,
  subscriptionStartDate: new Date(),
  subscriptionEndDate: new Date(),
  subscriptionState: SubscriptionState.UNREGISTERED,
  deviceUiLanguage: DeviceUILanguage.ENGLISH,
  updateInterval: 1000,
  otaDownloadSettings: {
    url: "",
    token: "",
    controllerId: "",
  },
  logsDownloadSettings: {
    url: "",
    token: "",
    tokenValidFor: "",
  },
  commissioningDataDownloadSettings: {
    url: "",
    token: "",
    tokenValidFor: "",
  },
  factoryResetCommand: "",
  downloadLogsCommand: "",
  downloadCommissioningDataCommand: "",
};

interface DeviceSettingsFormProps {
  tenantID: number;
  device: Device;
}

export const DeviceSettingsForm = ({
  tenantID,
  device,
}: DeviceSettingsFormProps) => {
  const { t } = useTranslation();
  const { dispatchCliftState } = useCliftContext();
  const [settings, setSettings] = useState<DeviceSettings>(DEFAULT_SETTINGS);
  const [hasChanged, setChanged] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onValueChanged = (name: string, value: string | number) => {
    setChanged(true);
    setSettings({
      ...settings,
      [name]: value as string,
    });
  };

  const onOtaChange = (name: string, value: string | number) => {
    setChanged(true);
    setSettings({
      ...settings,
      otaDownloadSettings: {
        ...settings.otaDownloadSettings,
        [name]: value as string,
      },
    });
  };

  const onLogsChange = (name: string, value: string | number) => {
    setChanged(true);
    setSettings({
      ...settings,
      logsDownloadSettings: {
        ...settings.logsDownloadSettings,
        [name]: value as string,
      },
    });
  };

  const onCommissionChange = (name: string, value: string | number) => {
    setChanged(true);
    setSettings({
      ...settings,
      commissioningDataDownloadSettings: {
        ...settings.commissioningDataDownloadSettings,
        [name]: value as string,
      },
    });
  };

  const onClose = () => {
    if (hasChanged) {
      setCancelled(true);
    } else {
      closeSidePanel();
    }
  };

  const onSubmit = (deviceSettings: DeviceSettings) => {
    if (!deviceSettings) onClose();
    const requestBody = { ...deviceSettings } as EditDeviceSettingsRequest;
    return editDeviceSettings(tenantID, device.id, requestBody)
      .then(() => {
        closeSidePanel();
      })
      .catch((err) => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_edit_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  useEffect(() => {
    setSettings(
      device.deviceSettings ? device.deviceSettings : DEFAULT_SETTINGS
    );
  }, [device.deviceSettings]);

  return (
    <SideModal
      title={t("device_edit_settings", { ns: "lift" })}
      onClose={onClose}
    >
      <div className="device-settings-form">
        <form
          className="device-settings-form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(settings);
          }}
        >
          <div className="device-settings-form-lines">
            <Picker
              noCurrentText={t("select_contact", { ns: "lift" })}
              noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
              title={t("subscription_state", { ns: "lift" })}
              items={Object.values(SubscriptionState).map((state) => {
                return {
                  label: state.toString(),
                  value: state,
                } as Option<SubscriptionState>;
              })}
              disabled={true}
              currentValue={settings.subscriptionState}
              onSelect={function (state: SubscriptionState): void {
                setChanged(true);
                setSettings({
                  ...settings,
                  subscriptionState: state,
                });
              }}
            />

            <DateTimePicker
              onChange={(date: ValuePiece) => {
                setChanged(true);
                setSettings({
                  ...settings,
                  subscriptionStartDate: date,
                });
              }}
              value={settings.subscriptionStartDate}
            />
            <DateTimePicker
              onChange={(date: ValuePiece) => {
                setChanged(true);
                setSettings({
                  ...settings,
                  subscriptionEndDate: date,
                });
              }}
              value={settings.subscriptionEndDate}
            />

            <Picker
              noCurrentText={t("select_contact", { ns: "lift" })}
              noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
              title={t("device_ui_language", { ns: "lift" })}
              items={Object.values(DeviceUILanguage).map((state) => {
                return {
                  label: state.toString(),
                  value: state,
                } as Option<DeviceUILanguage>;
              })}
              currentValue={settings.deviceUiLanguage}
              onSelect={function (lang: DeviceUILanguage): void {
                setChanged(true);
                setSettings({
                  ...settings,
                  deviceUiLanguage: lang,
                });
              }}
            />
            <FormField
              name={"updateInterval"}
              title={t("update_interval", { ns: "lift" })}
              value={settings.updateInterval ?? ""}
              onValueChanged={onValueChanged}
            />

            <h2>{t("ota_download_settings_title", { ns: "lift" })}</h2>
            <FormField
              name={"url"}
              title={t("ota_download_url", { ns: "lift" })}
              value={settings.otaDownloadSettings.url ?? ""}
              onValueChanged={onOtaChange}
            />
            <FormField
              name={"token"}
              title={t("ota_download_token", { ns: "lift" })}
              value={settings.otaDownloadSettings.token ?? ""}
              onValueChanged={onOtaChange}
            />
            <FormField
              name={"controllerId"}
              title={t("ota_download_controller_id", { ns: "lift" })}
              value={settings.otaDownloadSettings.controllerId ?? ""}
              onValueChanged={onOtaChange}
            />

            <h2>{t("logs_download_settings_title", { ns: "lift" })}</h2>
            <FormField
              name={"url"}
              title={t("logs_download_url", { ns: "lift" })}
              value={settings.logsDownloadSettings.url ?? ""}
              onValueChanged={onLogsChange}
            />
            <FormField
              name={"token"}
              title={t("logs_download_token", { ns: "lift" })}
              value={settings.logsDownloadSettings.token ?? ""}
              onValueChanged={onLogsChange}
            />
            <FormField
              name={"tokenValidFor"}
              title={t("logs_download_token_valid_for", { ns: "lift" })}
              value={settings.logsDownloadSettings.tokenValidFor ?? ""}
              onValueChanged={onLogsChange}
            />

            <h2>
              {t("commissioning_data_download_settings_title", { ns: "lift" })}
            </h2>
            <FormField
              name={"url"}
              title={t("commissioning_data_download_url", { ns: "lift" })}
              value={settings.commissioningDataDownloadSettings.url ?? ""}
              onValueChanged={onCommissionChange}
            />
            <FormField
              name={"token"}
              title={t("commissioning_data_download_token", { ns: "lift" })}
              value={settings.commissioningDataDownloadSettings.token ?? ""}
              onValueChanged={onCommissionChange}
            />
            <FormField
              name={"tokenValidFor"}
              title={t("commissioning_data_download_token_valid_for", {
                ns: "lift",
              })}
              value={
                settings.commissioningDataDownloadSettings.tokenValidFor ?? ""
              }
              onValueChanged={onCommissionChange}
            />

            <h2>Commands</h2>
            <FormField
              name={"factoryResetCommand"}
              title={t("device_factory_reset_command", { ns: "lift" })}
              value={settings.factoryResetCommand ?? ""}
              onValueChanged={onValueChanged}
            />
            <FormField
              name={"downloadLogsCommand"}
              title={t("device_download_logs_command", { ns: "lift" })}
              value={settings.downloadLogsCommand ?? ""}
              onValueChanged={onValueChanged}
            />
            <FormField
              name={"downloadCommissioningDataCommand"}
              title={t("device_download_commissioning_data_command", {
                ns: "lift",
              })}
              value={settings.downloadCommissioningDataCommand ?? ""}
              onValueChanged={onValueChanged}
            />
          </div>
          <SideModalButtonBar onCancel={onClose} showSave />
        </form>

        <ConfirmDialog
          isOpen={cancelled}
          onCancel={() => {
            setCancelled(false);
          }}
          onConfirm={() => {
            closeSidePanel();
          }}
        />
      </div>
    </SideModal>
  );
};
