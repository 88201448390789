import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import { InviteFormValues } from "../../models/user";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { SideModalButtonBar } from "../../components/SideModalButtonBar";
import {
  StannahRole,
  RoleFormValues,
  EmployeeListEditAllowedRoles,
} from "../../models/role";
import "./Employees.css";
import FormField from "../../components/FormField";
import { RolesForm } from "./RolesForm";
import { emailRegex } from "../../app/constants";
import { isRoleForAction } from "../../hooks/useRoles";

export const enum CreateEmployeePages {
  EmployeePage,
  EmployeeRolesPage,
}

export interface InviteEmployeesFormProps {
  onSubmit: (emails: string[], roles: RoleFormValues[]) => Promise<void>;
  onClose: () => void;
  initialValues?: InviteFormValues;
}

export const InviteEmployeesForm = ({
  onSubmit,
  onClose,
  initialValues = {
    emails: [],
    roles: [],
  },
}: InviteEmployeesFormProps) => {
  const { t } = useTranslation();
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState(initialValues?.emails || []);
  const [roles, setRoles] = useState<RoleFormValues[]>(
    initialValues?.roles || []
  );
  const employeeForm = useRef<HTMLFormElement>(null);

  const onHandleFieldChanged = (_name: string, value: string | number) => {
    setChanged(true);
    setEmailInput(value.toString());
  };

  const checkForEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      e.preventDefault();
      addEmailFromInput();
    }
  };

  const addEmailFromInput = () => {
    if (!emailRegex.test(emailInput)) {
      return;
    }
    setChanged(true);
    setEmails([...emails, emailInput]);
    setEmailInput("");
  };

  const onHandleRoleChanged = (newRole: StannahRole, index: number) => {
    setChanged(true);
    const newRoles = roles.map((employeeRole, i) => {
      if (i === index) {
        return {
          ...employeeRole,
          role: newRole,
        };
      }
      return employeeRole;
    });
    setRoles(newRoles);
  };

  return (
    <div className="employee-form">
      <form ref={employeeForm}>
        <div className="employee-form-role-page">
          <div className="employee-form-creation-row">
            <div>
              <h3>{t("employee_info", { ns: "employee" })}</h3>
              <p>{t("employee_info_hint", { ns: "employee" })}</p>
              <h4>
                <b>{t("employee_info_note", { ns: "employee" })}</b>
              </h4>
            </div>
          </div>

          <div className="employee-form-creation-row">
            <FormField
              title={t("email", { ns: "customer" })}
              name="email"
              value={emailInput ?? ""}
              onValueChanged={onHandleFieldChanged}
              onKeyDown={checkForEnter}
              onBlur={addEmailFromInput}
            />
          </div>
          <div className="email-chips">
            {(emails || []).map((email, i) => (
              <Chip
                key={"email_" + i}
                color="primary"
                label={email}
                onDelete={() => setEmails(emails.toSpliced(i, 1))}
              />
            ))}
          </div>
          <div>
            <RolesForm
              handleRoleChanged={onHandleRoleChanged}
              setChanged={setChanged}
              setRoles={setRoles}
              roles={roles}
              hasEditRights={isRoleForAction(EmployeeListEditAllowedRoles)}
            />
          </div>

          <div className="employee-form-errors"></div>
          <SideModalButtonBar
            onCancel={() => {
              if (hasChanged) {
                setCanceled(true);
              } else {
                onClose();
              }
            }}
            onSave={() => {
              addEmailFromInput();
              onSubmit(emails, roles);
            }}
            showSave
            saveDisabled={roles.length == 0 || emails.length == 0}
          />
        </div>
      </form>
      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
