import FormField from "../../components/FormField";
import { TenantResponseFull } from "../../models/tenant";
import { useTranslation } from "react-i18next";
import { SideModalButtonBar } from "../../components/SideModalButtonBar";
import "./TenantFormDetailsPage.css";

export interface TenantFormDetailsPageProps {
  originalTenant?: TenantResponseFull | undefined;
  tenant: Omit<TenantResponseFull, "id" | "tenant">;
  showSave: boolean;
  onFieldChanged: (name: string, value: string | number) => void;
  onCancel: () => void;
  onNext?: () => void;
  onBack?: () => void;
}

export const TenantFormDetailsPage = ({
  tenant,
  originalTenant,
  showSave,
  onFieldChanged,
  onCancel,
  onNext,
}: TenantFormDetailsPageProps) => {
  const { t } = useTranslation();

  const isRequiredFieldsFilled = (): boolean => {
    if (tenant.name && tenant.businessId && tenant.phoneNr) {
      return true;
    }
    return false;
  };

  return (
    <div className="tenant-form-details-page">
      <div id="input-container" className="tenant-form-details-page-inputs">
        <FormField
          title={t("tenant_id", { ns: "tenant" })}
          name="id"
          disabled
          value={originalTenant?.id}
          onValueChanged={onFieldChanged}
          hintText={t("tenant_id_hint", { ns: "tenant" })}
        />

        <FormField
          title={t("name")}
          name="name"
          required
          value={tenant.name ?? ""}
          onValueChanged={onFieldChanged}
        />

        <FormField
          title={t("business_id", { ns: "tenant" })}
          name="businessId"
          value={tenant.businessId ?? ""}
          onValueChanged={onFieldChanged}
          required
        />

        <FormField
          title={t("address", { ns: "contact" })}
          name="address"
          value={tenant.address ?? ""}
          onValueChanged={onFieldChanged}
        />

        <FormField
          title={t("phone", { ns: "contact" })}
          name="phoneNr"
          required
          type="tel"
          value={tenant.phoneNr ?? ""}
          onValueChanged={onFieldChanged}
        />

        <FormField
          title={t("email", { ns: "contact" })}
          name="email"
          type="email"
          value={tenant.email ?? ""}
          onValueChanged={onFieldChanged}
        />
      </div>

      <SideModalButtonBar
        nextDisabled={!isRequiredFieldsFilled()}
        showSave={showSave}
        onNext={() => onNext?.()}
        onCancel={onCancel}
      />
    </div>
  );
};
