import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import List from "../../components/List";
import { CustomerOptionsMenu } from "./CustomerOptionsMenu";
import { CustomerListItem } from "../../models/customer-list-item";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import {
  EditCustomerAllowedRoles,
  ViewCustomerAllowedRoles,
} from "../../models/role";

export interface CustomerListProps {
  customers: CustomerListItem[];
  setSelectedCustomerID(customerId: number): void;
}

export const CustomerList = ({
  customers,
  setSelectedCustomerID,
}: CustomerListProps) => {
  const { t, i18n } = useTranslation();
  const [loading] = useState(false);
  const { dispatchCliftState } = useCliftContext();

  const columnHelper = createColumnHelper<CustomerListItem>();

  const columns: ColumnDef<CustomerListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => t("name", { ns: "translation" }),
        cell: (info) =>
          isRoleForAction(ViewCustomerAllowedRoles) ? (
            <Link
              to={info.row.original.id.toString()}
              onClick={() => {
                dispatchCliftState({
                  type: CliftReducerAction.SetFromOriginalPage,
                  fromOriginalPage: "/customers",
                });
              }}
            >
              {info.row.original.name}
            </Link>
          ) : (
            <>{info.row.original.name}</>
          ),
      }),
      columnHelper.accessor("organization", {
        header: () => t("organization", { ns: "customer" }),
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor((row) => row.lifts + "", {
        id: "lifts",
        header: () => t("lifts", { ns: "customer" }),
      }),

      columnHelper.accessor("city", {
        header: () => t("city", { ns: "customer" }),
        cell: (info) => info.getValue(),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          const customer = customers.find((t) => t.id === info.row.original.id);
          return customer
            ? isRoleForAction(EditCustomerAllowedRoles) && (
                <CustomerOptionsMenu
                  customerID={customer.id}
                  setSelectedCustomerID={setSelectedCustomerID}
                />
              )
            : undefined;
        },
      }),
    ],
    [i18n.language]
  );

  if (!loading) {
    return (
      <List<CustomerListItem>
        columns={columns}
        data={customers}
        showSearch={true}
      />
    );
  } else {
    return <progress></progress>;
  }
};
