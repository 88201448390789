import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../assets/Home.svg";
import { ReactComponent as LiftIcon } from "../assets/Lift.svg";
import logo from "../assets/logo.svg";
import { ReactComponent as ProfileIcon } from "../assets/Profile.svg";
import { useCliftContext } from "../hooks/useCliftContext";
import { isRoleForAction } from "../hooks/useRoles";
import {
  EmployeeListViewAllowedRoles,
  LiftListAllowedRoles,
  ListCustomersAllowedRoles,
  ListTenantsAllowedRoles,
} from "../models/role";
import { TenantType } from "../models/tenant";
import "./Navbar.css";

interface Tenant {
  tenantType: TenantType;
}
interface ClientPrincipal {
  userRoles?: string[];
}

interface CurrentUser {
  clientPrincipal?: ClientPrincipal;
}

interface CliftState {
  currentTenant?: Tenant;
}

const Navbar = () => {
  const { cliftState, currentUser } = useCliftContext() as {
    cliftState: CliftState;
    currentUser: CurrentUser;
  };
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isAuthenticated: boolean =
    !!cliftState.currentTenant &&
    currentUser.clientPrincipal?.userRoles?.includes("authenticated") === true;
  const { tenantType } = cliftState.currentTenant! || {
    tenantType: TenantType.DEALER,
  };

  const canListTenants =
    isRoleForAction(ListTenantsAllowedRoles) &&
    (tenantType === TenantType.ROOT || tenantType === TenantType.COUNTRY);
  const canListCustomers =
    isRoleForAction(ListCustomersAllowedRoles) &&
    tenantType === TenantType.DEALER;
  const canListLifts = isRoleForAction(LiftListAllowedRoles);
  const canListEmployees = isRoleForAction(EmployeeListViewAllowedRoles);

  const menuItems: React.ReactNode[] = [];

  menuItems.push(
    <li key="icon">
      <Link to="/">
        <div className="navbar-menu-item">
          <span>
            <HomeIcon className="navbar-icon" />
          </span>
          <span>{t("home")}</span>
        </div>
      </Link>
    </li>
  );

  // Tenants menu item
  if (isAuthenticated) {
    if (canListTenants) {
      menuItems.push(
        <li key="tenants">
          <Link to="/tenants">
            <div className="navbar-menu-item">
              <span>
                <ProfileIcon className="navbar-icon" />
              </span>
              <span>
                {tenantType === TenantType.ROOT &&
                  t("markets", { ns: "tenant" })}
                {tenantType === TenantType.COUNTRY &&
                  t("tenants", { ns: "tenant" })}
              </span>
            </div>
          </Link>
        </li>
      );
    }
  }
  // Customers menu item
  if (canListCustomers) {
    menuItems.push(
      <li key="customers">
        <Link to="/customers">
          <div className="navbar-menu-item">
            <span>
              <ProfileIcon className="navbar-icon" />
            </span>
            <span>{t("customers", { ns: "customer" })}</span>
          </div>
        </Link>
      </li>
    );
  }
  // Lifts menu item
  if (canListLifts) {
    menuItems.push(
      <li key="lifts">
        <Link to="/lifts">
          <div className="navbar-menu-item">
            <span>
              <LiftIcon className="navbar-icon" />
            </span>
            <span>{t("lifts", { ns: "lift" })}</span>
          </div>
        </Link>
      </li>
    );
  }
  // Employees menu item
  if (canListEmployees) {
    menuItems.push(
      <li key="employees">
        <Link to="/users">
          <div className="navbar-menu-item">
            <span>
              <ProfileIcon className="navbar-icon" />
            </span>
            <span>{t("employees", { ns: "employee" })}</span>
          </div>
        </Link>
      </li>
    );
  }

  return (
    <nav className={collapsed ? "navbar-collapsed" : "navbar"}>
      <img alt="Stannah" id="logo" src={logo} />
      <button id="navbar-collapse" onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? ">" : "<"}
      </button>
      <menu>{menuItems}</menu>
    </nav>
  );
};

export default Navbar;
