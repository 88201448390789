import { DeviceRole } from "models/role";
import { ReactComponent as DeleteIcon } from "../../../../assets/Delete.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/Plus.svg";
import FormField from "../../../../components/FormField";
import SideModal from "../../../../components/SideModal";
import { SideModalButtonBar } from "../../../../components/SideModalButtonBar";
import "./Carers.css";
import { useEditCarers } from "./EditCarersHook";

export interface EditCarersProps {
  deviceId: number;
  initialCarers: DeviceRole[];
}

export type EditCarersFormValues = {
  carers: CarerFormValues[];
};

export type CarerFormValues = {
  userId: number | null;
  email: string;
};

export const EditCarers = ({ deviceId, initialCarers }: EditCarersProps) => {
  const {
    t,
    handleAddCarer,
    handleEditCarer,
    handleDeleteCarer,
    handleSave,
    handleClose,
    invalidForm,
    carersForm,
  } = useEditCarers(deviceId, initialCarers);
  return (
    <SideModal
      title={t("edit_carers_title", { ns: "lift" })}
      onClose={handleClose}
    >
      <div className="carer-form-upper-row">
        <div>
          <h3>{t("lift_carers", { ns: "lift" })}</h3>
          <h4>{t("lift_carers_add_hint", { ns: "lift" })}</h4>
        </div>
        <div>
          <button
            className="button secondary-button"
            id="create_carer_button"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleAddCarer();
            }}
          >
            <PlusIcon />
            {t("add_carer", { ns: "lift" })}
          </button>
        </div>
      </div>

      {carersForm.carers.map((carer: CarerFormValues, i: number) => (
        <div key={i} className="carer-form-row">
          <FormField
            title={t("email", { ns: "contact" })}
            name={"email"}
            type="email"
            disabled={carer.userId != null}
            autoComplete="off"
            value={carer.email}
            onValueChanged={(_, val) => handleEditCarer(i, val as string)}
            required
          />

          <button
            title={t("delete", { ns: "translation" })}
            className="delete-carer-button"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleDeleteCarer(i);
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}
      <SideModalButtonBar
        showSave={true}
        saveDisabled={invalidForm()}
        onCancel={handleClose}
        onSave={handleSave}
      />
    </SideModal>
  );
};
