import FormField from "../../components/FormField";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { Contact } from "../../models/contact";
import { SideModalButtonBar } from "../../components/SideModalButtonBar";
import "./TenantFormContactsPage.css";

export interface AddTenantContactsFormProps {
  supportContacts: Contact[];
  emergencyContact: Contact;
  showSave: boolean;
  onCancel: () => void;
  onBack: () => void;
  onAddNewContact: () => void;
  onDeleteContact: (index: number) => void;
  onContactChanged: (name: string, i: number, value: string) => void;
  onEmergencyNumberChanged: (value: string) => void;
}

export const TenantFormContactsPage = ({
  supportContacts = [],
  emergencyContact,
  showSave = false,
  onCancel,
  onBack,
  onAddNewContact,
  onDeleteContact,
  onContactChanged,
  onEmergencyNumberChanged,
}: AddTenantContactsFormProps) => {
  const { t } = useTranslation();

  const isRequiredFieldsFilled = (): boolean => {
    if (
      emergencyContact.phoneNr &&
      supportContacts[0] &&
      supportContacts[0].displayName &&
      supportContacts[0].phoneNr
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="tenant-form-contacts-page">
      <div className="tenant-form-contacts-page-creation-row">
        <div>
          <h3>{t("add_contacts_subtilte", { ns: "tenant" })}</h3>
          <h4>{t("add_contacts_note", { ns: "tenant" })}</h4>
        </div>

        <button
          className="button"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onAddNewContact();
          }}
        >
          <PlusIcon />
          {t("add_contact_lift_btn", { ns: "tenant" })}
        </button>
      </div>

      <div className="tenant-form-contacts-page-inputs">
        <div className="tenant-form-contacts-page-emergency-row">
          <FormField
            title={t("contact_name", { ns: "tenant" })}
            name="emergencyContact"
            autoComplete="off"
            disabled
            value={t("emergency_number", { ns: "contact" })}
          />
          <FormField
            title={t("contact_phone_number", { ns: "tenant" })}
            name="phoneNr"
            required
            type="number"
            maxLength={20}
            autoComplete="off"
            value={emergencyContact?.phoneNr}
            onValueChanged={(_, value) => {
              onEmergencyNumberChanged(value as string);
            }}
          />
        </div>

        {supportContacts.map((contact, i) => (
          <div key={i} className="tenant-form-contacts-page-creation-input-row">
            <FormField
              title={t("contact_name", { ns: "tenant" })}
              name="displayName"
              required
              autoComplete="off"
              maxLength={10}
              value={contact.displayName}
              onValueChanged={(name, value) =>
                onContactChanged(name, i, value as string)
              }
            />
            <FormField
              title={t("contact_phone_number", { ns: "tenant" })}
              name="phoneNr"
              required
              maxLength={20}
              autoComplete="off"
              value={contact.phoneNr}
              onValueChanged={(name, value) =>
                onContactChanged(name, i, value as string)
              }
            />
            <button
              className="tenant-form-contacts-page-delete-btn"
              title={t("delete", { ns: "translation" })}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onDeleteContact(i);
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
      </div>

      <SideModalButtonBar
        showSave={showSave}
        saveDisabled={!isRequiredFieldsFilled()}
        onBack={() => onBack?.()}
        onCancel={onCancel}
      />
    </div>
  );
};
