import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import { editEmployee, fetchEmployee } from "../../services/employees-api";
import { getErrorCode } from "../../services/clift-api-errors";
import { RoleFormValues } from "models/role";
import { EditEmployeeForm } from "./EditEmployeeForm";
import { EmployeeResponseFull } from "models/user";

export interface EditEmployeeProps {
  userId: number;
}

export const EditEmployee = ({ userId }: EditEmployeeProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [employee, setEmployee] = useState<EmployeeResponseFull>();
  useEffect(() => {
    fetchEmployee(cliftState.currentTenant!.id, userId)
      .then((res) => {
        setEmployee(res);
      })
      .catch((err) => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("employee_get_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  }, []);

  if (loading) return <progress></progress>;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onEditEmployees = (roles: RoleFormValues[]) => {
    if (!cliftState.currentTenant) throw new Error("No current tenant");

    setLoading(true);

    return editEmployee(cliftState.currentTenant.id, userId, roles)
      .then(() => {
        setLoading(false);
        closeSidePanel();
      })
      .catch((err: Error) => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("employee_edit_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };
  if (!employee) return <progress />;
  return (
    <SideModal
      title={t("edit_employee", { ns: "employee" })}
      pageCount={1}
      onClose={closeSidePanel}
    >
      <EditEmployeeForm
        onSubmit={onEditEmployees}
        onClose={closeSidePanel}
        initialValues={{ email: employee.email, roles: employee.roles }}
      />
    </SideModal>
  );
};
