import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled } from "../../../components/Card";
import { Device } from "../../../models/device";
import { DeviceStateChip } from "./DeviceStateChip";
import "./DeviceInfoCard.css";

export interface DeviceInfoCardProps {
  device: Device;
}

export const DeviceInfoCard = ({ device }: DeviceInfoCardProps) => {
  const { t } = useTranslation();

  return (
    <Card title={device.model}>
      <div className="device-info-card-state">
        <DeviceStateChip
          subscriptionState={device.deviceSettings.subscriptionState}
        />
      </div>
      <CardDetailTitled
        title={t("serial_number", { ns: "lift" })}
        value={device.serialNumber.toString()}
      />
      <CardDetailTitled
        title={t("nickname", { ns: "lift" })}
        value={device.contactInfo?.nickname}
      />
    </Card>
  );
};
