import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled } from "../../../components/Card";
import { Device } from "../../../models/device";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { isRoleForAction } from "../../../hooks/useRoles";
import { LiftSimEditAllowedRoles } from "../../../models/role";

export interface SimSettingsCardProps {
  device: Device;
}

export const SimSettingsCard = ({ device }: SimSettingsCardProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();

  const handleEdit = () => {
    if (!cliftState.currentTenant) return;

    dispatchCliftState({
      type: CliftReducerAction.EditSimSettings,
      tenantID: cliftState.currentTenant.id,
      device,
    });
  };
  const { t } = useTranslation();
  return (
    <Card
      title={t("sim_settings", { ns: "lift" })}
      editAllowed={isRoleForAction(LiftSimEditAllowedRoles)}
      onEdit={handleEdit}
    >
      <CardDetailTitled
        title={t("sim_apn", { ns: "lift" })}
        value={device.simSettings?.apn}
      />
      <CardDetailTitled
        title={t("sim_username", { ns: "lift" })}
        value={device.simSettings?.username}
      />
      <CardDetailTitled
        title={t("sim_password", { ns: "lift" })}
        value={device.simSettings?.password}
        type="password"
      />
    </Card>
  );
};
