import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled } from "../../../components/Card";
import { NetworkInfo } from "../../../models/device";
import { fetchNetworkInfo } from "../../../services/devices-api";
import { getErrorCode } from "../../../services/clift-api-errors";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";

export interface NetworkInfoCardProps {
  deviceId: number | undefined;
}

export const NetworkInfoCard = ({ deviceId }: NetworkInfoCardProps) => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [networkInfo, setNetworkInfo] = useState<NetworkInfo>();

  const updateDeviceState = (
    tenantId: number | undefined,
    deviceId: number | undefined
  ) => {
    if (!tenantId || !deviceId) {
      return;
    }
    fetchNetworkInfo(tenantId, deviceId)
      .then((info) => {
        setNetworkInfo(info);
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_state_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  useEffect(() => {
    if (deviceId !== undefined && cliftState.currentTenant?.id) {
      updateDeviceState(cliftState.currentTenant?.id, deviceId);
    }
  }, [deviceId, cliftState.currentTenant]);

  return (
    <Card title={t("network_info_title", { ns: "lift" })}>
      <CardDetailTitled
        title={t("imsi", { ns: "lift" })}
        value={getDisplayValue(networkInfo?.imsi)}
      />
      <CardDetailTitled
        title={t("imei", { ns: "lift" })}
        value={getDisplayValue(networkInfo?.imei)}
      />
      <CardDetailTitled
        title={t("iccid", { ns: "lift" })}
        value={getDisplayValue(networkInfo?.iccid)}
      />
    </Card>
  );
};

const getDisplayValue = (value: string | undefined) => {
  return value && value != "null" ? value : "";
};
