import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { ContextMenu } from "../../components/ContextMenu";
import { TenantListItem } from "../../models/tenant-list-item";
import {
  DeleteTenantAllowedRoles,
  EditTenantAllowedRoles,
} from "../../models/role";
import { ReactComponent as EditIcon } from "../../assets/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { EditTenant } from "./EditTenant";

export const TenantOptionsMenu = ({
  tenant,
  setSelectedTenant,
}: {
  tenant: TenantListItem;
  setSelectedTenant(tenant: TenantListItem): void;
}) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();

  const handleEditTenant = (_: string) => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: <EditTenant tenantID={tenant?.id ?? 0} child={true} />,
    });
  };

  return (
    <ContextMenu
      items={[
        {
          id: "edit",
          title: t("edit"),
          hidden: !isRoleForAction(EditTenantAllowedRoles),
          onClicked: handleEditTenant,
          icon: <EditIcon />,
        },
        {
          id: "delete",
          title: t("delete_tenant", { ns: "tenant" }),
          hidden: !isRoleForAction(DeleteTenantAllowedRoles),
          onClicked: () => setSelectedTenant(tenant),
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
