import { useTranslation } from "react-i18next";
import "./SideModalButtonBar.css";

interface SideModalButtonBarProps {
  showSave: boolean;
  saveDisabled?: boolean;
  nextDisabled?: boolean;
  onCancel?: () => void;
  onNext?: () => void;
  onBack?: () => void;
  onSave?: () => void;
}

export const SideModalButtonBar = ({
  showSave,
  saveDisabled = false,
  nextDisabled = true,
  onCancel,
  onNext,
  onBack,
  onSave,
}: SideModalButtonBarProps) => {
  const { t } = useTranslation();
  return (
    <div className="side-modal-button-bar">
      {onCancel && (
        <button
          className="button secondary-button"
          id="cancel_admin_button"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          {t("cancel")}
        </button>
      )}
      {onBack && (
        <button
          className="button secondary-button"
          id="back_button"
          onClick={(e) => {
            e.preventDefault();
            onBack?.();
          }}
        >
          {t("back")}
        </button>
      )}
      {onNext && (
        <button
          disabled={nextDisabled}
          className="button"
          id="next_button"
          onClick={(e) => {
            e.preventDefault();
            onNext();
          }}
        >
          {t("next")}
        </button>
      )}
      {showSave && (
        <button
          type="submit"
          disabled={saveDisabled}
          className="button"
          id="submit_button"
          onClick={(e) => {
            if (onSave) {
              e.preventDefault();
              onSave();
            }
          }}
        >
          {t("save")}
        </button>
      )}
    </div>
  );
};
