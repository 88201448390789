import "./Dropdown.css";
import { ReactComponent as UKFlagIcon } from "../assets/UK-flag.svg";
import { ReactComponent as FRFlagIcon } from "../assets/FR-flag.svg";
import { ReactComponent as ITFlagIcon } from "../assets/IT-flag.svg";

export interface LanguageOption {
  code: string;
  name: string;
}

export interface LanguageDropdownProps {
  currentCode: string | undefined;
  noCurrentLanguage: string;
  noLanguageOptionsText: string;
  disabled?: boolean;
  items: LanguageOption[];
  onSelect: (code: string) => void;
}

export const LanguageDropdown = ({
  currentCode,
  noCurrentLanguage,
  noLanguageOptionsText,
  disabled = false,
  items,
  onSelect,
}: LanguageDropdownProps) => {
  const getCurrent = () => {
    if (typeof currentCode !== "undefined") {
      return items.find((i) => i.code === currentCode)?.code;
    }
    return;
  };

  const getFlagIconForCountry = (code: string) => {
    switch (code) {
      case "en":
        return <UKFlagIcon />;
      case "fr":
        return <FRFlagIcon />;
      case "it":
        return <ITFlagIcon />;
      default:
        return undefined;
    }
  };

  return (
    <div className="dropdown">
      <button className="dropdown-button" disabled={disabled}>
        {getFlagIconForCountry(getCurrent()!) ?? noCurrentLanguage}
      </button>
      {!disabled && (
        <div className="dropdown-content">
          {items.length ? (
            items.map((item, index) => (
              <button
                type="button"
                key={index}
                onClick={() => onSelect(item.code)}
                disabled={getCurrent() === item.code}
              >
                {getFlagIconForCountry(item.code)}{" "}
                {item.name ?? noCurrentLanguage}
              </button>
            ))
          ) : (
            <div className="dropdown-no-content">{noLanguageOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
};
