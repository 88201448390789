import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { toast } from "react-toast";
import SideModal from "../../components/SideModal";
import { Customer } from "../../models/customer";
import { CustomerForm } from "./CustomerForm";
import { CustomerFormValues } from "../../models/customer";
import { updateCustomer } from "../../services/customers-api";
import { Contact } from "../../models/contact";
import {
  getLocalizedErrorReason,
  getErrorCode,
} from "../../services/clift-api-errors";

export interface EditCustomerProps {
  page?: number | undefined;
  editingCustomer: Customer | undefined;
}

export const EditCustomer = ({ page, editingCustomer }: EditCustomerProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [currentPageIndex, setCurrentPageIndex] = useState(page ?? 0);
  const { t } = useTranslation();

  useEffect(() => {
    if (editingCustomer) {
      dispatchCliftState({
        type: CliftReducerAction.FetchContacts,
        tenantID: cliftState.currentTenant?.id,
        customerID: editingCustomer.id,
      });
    }
  }, [editingCustomer]);

  if (!editingCustomer) return null;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.EditCustomer,
      customer: undefined,
      customerID: 0,
    });
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onUpdateCustomer = (
    formValues: CustomerFormValues,
    contacts: Contact[]
  ) => {
    if (!editingCustomer) {
      throw new Error("No current customer");
    }

    return updateCustomer(
      editingCustomer,
      formValues,
      cliftState.currentTenant!.id,
      contacts
    )
      .then(() => {
        closeSidePanel();
      })
      .catch((err) => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("customer_edit_http_fail", {
            ns: "alerts",
            reason: getLocalizedErrorReason(err),
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("customer_edit_toast_error", {
              ns: "alerts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  return (
    <SideModal
      title={t("edit_customer", { ns: "customer" })}
      pageCount={2}
      pageIndex={currentPageIndex}
      onClose={closeSidePanel}
    >
      <CustomerForm
        pageIndex={currentPageIndex}
        initialValues={editingCustomer}
        onSubmit={onUpdateCustomer}
        onClose={closeSidePanel}
        onSwitchPage={setCurrentPageIndex}
        editingCustomer={editingCustomer}
        showSaveOnFirstPage={true}
      />
    </SideModal>
  );
};
