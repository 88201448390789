import { useTranslation } from "react-i18next";
import { SubscriptionState } from "../../../models/device";

interface DeviceStateChipProps {
  subscriptionState: SubscriptionState;
}

export const DeviceStateChip = ({
  subscriptionState,
}: DeviceStateChipProps) => {
  const { t } = useTranslation();

  const getSubscriptionState = (value: SubscriptionState) => {
    if (value === SubscriptionState.ACTIVE) {
      return (
        <div className="active-status">
          <span>{t("active", { ns: "lift" })}</span>
        </div>
      );
    } else if (value === SubscriptionState.SUSPENDED) {
      return (
        <div className="paused-status">
          <span>{t("suspended", { ns: "lift" })}</span>
        </div>
      );
    } else {
      // UNREGISTERED
      return (
        <div className="inactive-status">
          <span>{t("unregistered", { ns: "lift" })}</span>
        </div>
      );
    }
  };

  return getSubscriptionState(subscriptionState);
};
