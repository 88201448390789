import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TenantForm } from "./TenantForm";
import { createTenant } from "../../services/tenants-api";
import SideModal from "../../components/SideModal";
import { TenantAdmin, TenantFormValues, TenantType } from "../../models/tenant";
import { getErrorCode } from "../../services/clift-api-errors";
import { Contact } from "../../models/contact";

export const CreateTenant = () => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const { t } = useTranslation();

  if (loading) return <progress></progress>;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onCreateTenant = (
    tenant: TenantFormValues,
    admins: TenantAdmin[],
    contacts: Contact[]
  ) => {
    if (!cliftState.currentTenant) throw new Error("No current tenant");

    setLoading(true);

    const parentTenantId = cliftState.currentTenant.id;
    const tenantType =
      cliftState.currentTenant.tenantType === TenantType.ROOT
        ? TenantType.COUNTRY
        : TenantType.DEALER;

    const tenantData = {
      ...tenant,
      admins,
      contacts,
    };

    return createTenant(tenantData, parentTenantId, tenantType)
      .then(() => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.FetchTenants,
        });
        closeSidePanel();
      })
      .catch((err) => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("create_tenant_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  return (
    <SideModal
      title={
        cliftState.currentTenant?.tenantType === TenantType.ROOT
          ? t("create_market_tenant", { ns: "tenant" })
          : t("create_tenant", { ns: "tenant" })
      }
      showPager
      pageCount={3}
      pageIndex={currentPageIndex}
      onClose={closeSidePanel}
    >
      <TenantForm
        pageIndex={currentPageIndex}
        onSubmit={onCreateTenant}
        onClose={closeSidePanel}
        onSwitchPage={setCurrentPageIndex}
        showSaveOnAllPages={false}
      />
    </SideModal>
  );
};
