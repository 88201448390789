import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ConfirmDelete } from "../../components/ConfirmDelete";
import Page from "../../components/Page";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { CustomerListItem } from "../../models/customer-list-item";
import { AddCustomersAllowedRoles } from "../../models/role";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { deleteCustomer, fetchCustomers } from "../../services/customers-api";
import { CustomerList } from "./CustomerList";
import "./CustomersPage.css";

export const CustomersPage = () => {
  const { t } = useTranslation();
  const isAddCustomersAllowed: boolean = isRoleForAction(
    AddCustomersAllowedRoles
  );
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [customers, setCustomers] = useState<CustomerListItem[]>();
  const [selectedCustomerID, setSelectedCustomerID] = useState<number>();

  useEffect(() => {
    if (cliftState.currentTenant && !customers) {
      fetchCustomers(cliftState.currentTenant.id)
        .then((res) => {
          setCustomers(res ?? []);
        })
        .catch((err) => {
          setCustomers([]);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("customer_list_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [cliftState.currentTenant, customers]);

  useEffect(() => {
    if (!cliftState.sidepanelContent) {
      setCustomers(undefined);
    }
  }, [cliftState.sidepanelContent]);

  if (!cliftState.currentTenant) {
    return <></>;
  }

  if (!customers) {
    return <progress></progress>;
  }

  const handleDeleteCustomer = async () => {
    if (selectedCustomerID === undefined || !cliftState.currentTenant) return;

    await deleteCustomer(cliftState.currentTenant.id, selectedCustomerID).catch(
      (err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("delete_customer_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("delete_customer_toast_error", {
              ns: "alerts",
              reason: getLocalizedErrorReason(err),
            })
        );
      }
    );

    setSelectedCustomerID(undefined);
    setCustomers(undefined);
  };

  return (
    <Page
      title={t("customers", { ns: "customer" })}
      subtitle={t("customers_subtitle", { ns: "customer" })}
      button={
        isAddCustomersAllowed && (
          <button
            className="button"
            type="button"
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.AddCustomer,
              });
            }}
          >
            <PlusIcon />

            {t("add_new_customer", { ns: "customer" })}
          </button>
        )
      }
    >
      <>
        <CustomerList
          customers={customers}
          setSelectedCustomerID={(customerID: number | undefined) => {
            setSelectedCustomerID(customerID);
          }}
        />

        {selectedCustomerID !== undefined && (
          <ConfirmDelete
            title={t("delete_customer_question", { ns: "dialogs" })}
            text={t("confirm_customer_delete_question", {
              ns: "dialogs",
              customer: selectedCustomerID,
            })}
            isOpen={selectedCustomerID !== undefined}
            onCancel={() => setSelectedCustomerID(undefined)}
            onConfirmed={handleDeleteCustomer}
          />
        )}
      </>
    </Page>
  );
};
