import { Picker, Option } from "../../components/Picker";
import { StannahRole, RoleFormValues, getRoleList } from "../../models/role";
import { useTranslation } from "react-i18next";
import "./Employees.css";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useEffect, useState } from "react";

export interface RolesFormProps {
  handleRoleChanged: (newRole: StannahRole, index: number) => void;
  setChanged: (changed: boolean) => void;
  setRoles: (roles: RoleFormValues[]) => void;
  hasEditRights: boolean;
  roles: RoleFormValues[];
}

export const RolesForm = ({
  handleRoleChanged,
  setChanged,
  setRoles,
  hasEditRights,
  roles,
}: RolesFormProps) => {
  const { cliftState } = useCliftContext();
  const { t } = useTranslation();

  const [roleList, setRoleList] = useState<StannahRole[]>([]);
  useEffect(() => {
    const roleListByTenantType = getRoleList(
      cliftState.currentTenant?.tenantType
    );
    setRoleList(roleListByTenantType);
  }, [cliftState.currentTenant]);
  return (
    <div>
      <div className="employee-form-creation-row">
        <div>
          <h3>{t("role", { ns: "employee" })}</h3>
        </div>
      </div>
      {(roles || []).map((role, i) => (
        <div key={i} className="employee-form-role-employees">
          <Picker
            noCurrentText={t("select_role", { ns: "employee" })}
            noOptionsText={t("no_roles_to_select", { ns: "employee" })}
            items={roleList.map((role) => {
              return {
                label: t(role, { ns: "role" }),
                value: role,
              } as Option<StannahRole>;
            })}
            currentValue={role.role}
            onSelect={function (role: StannahRole): void {
              setChanged(true);
              handleRoleChanged(role, i);
            }}
          />
          <button
            className="button delete-role-button"
            title={t("delete", { ns: "translation" })}
            type="button"
            disabled={!hasEditRights}
            onClick={(e) => {
              e.preventDefault();
              setRoles(roles.toSpliced(i, 1));
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}
      <button
        className="button secondary-button"
        type="button"
        disabled={!hasEditRights}
        onClick={(e) => {
          e.preventDefault();
          setRoles([
            ...(roles || []),
            {
              tenantId: cliftState.currentTenant?.id,
              role: StannahRole.INSTALLER,
            },
          ]);
        }}
      >
        <PlusIcon />
        {t("add_new_role", { ns: "employee" })}
      </button>
    </div>
  );
};
