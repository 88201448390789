import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EmployeeListItem } from "../../models/user";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { getErrorCode } from "../../services/clift-api-errors";
import { deleteUser, fetchEmployees } from "../../services/employees-api";
import Page from "../../components/Page";
import { EmployeeList } from "./EmployeeList";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ConfirmDelete } from "../../components/ConfirmDelete";

export const EmployeesPage = () => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [employees, setEmployees] = useState<EmployeeListItem[]>();
  const [selectedUserID, setSelectedUserID] = useState<number>();

  useEffect(() => {
    if (cliftState.currentTenant && !employees) {
      fetchEmployees(cliftState?.currentTenant?.id)
        .then((res) => {
          setEmployees(res ?? []);
        })
        .catch((err) => {
          setEmployees([]);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("employees_list_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [cliftState.currentTenant, employees]);

  useEffect(() => {
    if (!cliftState.sidepanelContent) {
      setEmployees(undefined);
    }
  }, [cliftState.sidepanelContent]);

  if (!cliftState.currentTenant) {
    return <></>;
  }

  if (!employees) {
    return <progress></progress>;
  }

  const handleDeleteUser = async () => {
    if (selectedUserID === undefined || !cliftState.currentTenant) return;

    await deleteUser(selectedUserID, cliftState.currentTenant.id).catch(
      (err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("delete_user_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      }
    );

    setSelectedUserID(undefined);
    setEmployees(undefined);
  };

  return (
    <Page
      title={
        t("employees_prefix", { ns: "employee" }) +
        cliftState.currentTenant?.name
      }
      subtitle={t("employees_subtitle", { ns: "employee" })}
      button={
        <button
          className="button"
          type="button"
          onClick={() => {
            dispatchCliftState({
              type: CliftReducerAction.InviteUsers,
            });
          }}
        >
          <PlusIcon />
          {t("invite_new_employee", { ns: "employee" })}
        </button>
      }
    >
      <>
        <EmployeeList
          employees={employees}
          setSelectedUserID={(userID: number | undefined) => {
            setSelectedUserID(userID);
          }}
        />
        {selectedUserID !== undefined && (
          <ConfirmDelete
            title={t("delete_user_question", { ns: "dialogs" })}
            text={t("confirm_user_delete_question", {
              ns: "dialogs",
              user: selectedUserID,
            })}
            isOpen={selectedUserID !== undefined}
            onCancel={() => setSelectedUserID(undefined)}
            onConfirmed={handleDeleteUser}
          />
        )}
      </>
    </Page>
  );
};
