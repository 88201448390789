import { ContactCommonResponse, DependableContactResponse } from "./contact";
import { DeviceRole } from "./role";

export type Device = {
  id: number;
  activated: string | null;
  model: string;
  serialNumber: number;
  sipSettings: SipSettings;
  simSettings: SimSettings;
  contactInfo: ContactInfo;
  deviceSettings: DeviceSettings;
  carers: DeviceRole[];
};

export type DeviceState = {
  faultCode: string;
  currentLocation: DeviceLocation;
  footRestPosition: FootRestPosition;
  seatPosition: SeatPosition;
  hingePosition: HingePosition;
  swivelPosition: SwivelPosition;
  seatbeltStatus: SeatBeltStatus;
};

export type NetworkInfo = {
  imsi: string;
  imei: string;
  iccid: string;
};

export type DeviceSettings = {
  id: number;
  subscriptionStartDate: Date | null;
  subscriptionEndDate: Date | null;
  subscriptionState: SubscriptionState;
  deviceUiLanguage: DeviceUILanguage;
  updateInterval: number;

  otaDownloadSettings: OtaDownloadSettings;
  logsDownloadSettings: LogsDownloadSettings;
  commissioningDataDownloadSettings: CommissioningDataDownloadSettings;

  factoryResetCommand: string;
  downloadLogsCommand: string;
  downloadCommissioningDataCommand: string;
};

export type OtaDownloadSettings = {
  url: string;
  token: string;
  controllerId: string;
};

export type LogsDownloadSettings = {
  url: string;
  token: string;
  tokenValidFor: string;
};

export type CommissioningDataDownloadSettings = {
  url: string;
  token: string;
  tokenValidFor: string;
};

export enum DeviceLocation {
  TOPPED = "TOPPED",
  MOVING_UP = "MOVING_UP",
  MOVING_DOWN = "MOVING_DOWN",
  BOTTOM_STOP_POINT = "BOTTOM_STOP_POINT",
  MIDDLE_STOP_POINT = "MIDDLE_STOP_POINT",
  UPPER_STOP_POINT = "UPPER_STOP_POINT",
  PARK_POINT = "PARK_POINT",
  UNKNOWN = "UNKNOWN",
}

export enum FootRestPosition {
  IN_TRANSIT = "IN_TRANSIT",
  UNFOLDED = "UNFOLDED",
  FOLDED = "FOLDED",
  UNKNOWN = "UNKNOWN",
}

export enum SeatPosition {
  IN_TRANSIT = "IN_TRANSIT",
  UNFOLDED = "UNFOLDED",
  FOLDED = "FOLDED",
  PARTIALLY_UNFOLDED = "PARTIALLY_UNFOLDED",
  UNKNOWN = "UNKNOWN",
}

export enum HingePosition {
  IN_TRANSIT = "IN_TRANSIT",
  FOLDED = "FOLDED",
  RETRACTED = "RETRACTED",
  UNKNOWN = "UNKNOWN",
}

export enum SwivelPosition {
  IN_TRANSIT = "IN_TRANSIT",
  CENTERED = "CENTERED",
  SWIVELLED_LEFT = "SWIVELLED_LEFT",
  SWIVELLED_RIGHT = "SWIVELLED_RIGHT",
  UNKNOWN = "UNKNOWN",
}

export enum SeatBeltStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  UNKNOWN = "UNKNOWN",
}

export enum SubscriptionState {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  UNREGISTERED = "UNREGISTERED",
}

export enum DeviceUILanguage {
  ENGLISH = "ENGLISH",
  FRENCH = "FRENCH",
  ITALIAN = "ITALIAN",
}

export type SipSettings = {
  registrar: string | null;
  realm: string | null;
  username: string | null;
  password: string | null;
  phoneNumber: string | null;
};

export type SimSettings = {
  username: string | null;
  password: string | null;
  apn: string | null;
};

export type CompanyContact = {
  id: number;
  displayName: string | null;
  phoneNr: string;
};

export type ContactInfo = {
  customerId: number;
  speedDialNumber: CompanyContact;
  emergencyNumber: CompanyContact;
  supportNumber: CompanyContact;
  nickname: string;
  dependableContacts: DependableContactResponse[];
  otherContacts: ContactCommonResponse[];
};

export type DeviceInfo = {
  id: number;
  manufacturer: string | null;
  model: string | null;
  swVersion: string | null;
  osName: string | null;
  processorArchitecture: string | null;
  processorManufacturer: string | null;
  totalStorage: number | null;
  totalMemory: number | null;
};

export type DeviceListResponse = {
  id: number;
  serialNumber: string;
  nickname: string | null;
  subscriptionState: SubscriptionState;
};

export type UpdateCarersRequest = {
  carers: CarerRequest[];
};

export type CarerRequest = {
  userId: number;
  email: string;
};

export type DeviceRegistrationItem = {
  serialNumber: string;
  nickname?: string;
};
