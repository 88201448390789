import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "../translations/en.json" with { type: "json" };
import fr from "../translations/fr.json" with { type: "json" };
import it from "../translations/it.json" with { type: "json" };

export const supportedLngs = {
  en: "English",
  fr: "French",
  it: "Italian",
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      fr,
      it,
    },
    supportedLngs: Object.keys(supportedLngs),
    defaultNS: "translation",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
