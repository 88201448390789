import { DeviceRole } from "models/role";
import { useTranslation } from "react-i18next";
import { ReactComponent as PersonIcon } from "../../../../assets/Profile.svg";
import Card, { CardDetail } from "../../../../components/Card";
import "../LiftDetails.css";
import { CliftReducerAction } from "../../../../context/clift-context/clift-action-types";
import { EditCarers } from "./EditCarers";
import { useCliftContext } from "../../../../hooks/useCliftContext";

export interface DeviceRoleRowProps {
  deviceRole: { email: string };
}

export const DeviceRoleRow = ({ deviceRole }: DeviceRoleRowProps) => {
  return (
    <CardDetail
      icon={<PersonIcon width={"20px"} height={"20px"} />}
      value={
        <div className="contact-row">
          <div className="col">{deviceRole.email}</div>
        </div>
      }
    />
  );
};

export interface CarersCardProps {
  deviceId: number;
  carers: DeviceRole[];
}

export const CarersCard = ({ deviceId, carers }: CarersCardProps) => {
  const { dispatchCliftState } = useCliftContext();
  const handleEdit = () => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: <EditCarers deviceId={deviceId} initialCarers={carers} />,
    });
  };
  const { t } = useTranslation();
  return (
    <Card
      title={t("carers", { ns: "lift" })}
      subtitle={t("carers_subtitle", { ns: "lift" })}
      onEdit={handleEdit}
    >
      {carers
        .sort((a: DeviceRole, b: DeviceRole) => (a.email > b.email ? 1 : -1))
        .map((carer: DeviceRole) => (
          <DeviceRoleRow key={carer.userId} deviceRole={carer} />
        ))}
    </Card>
  );
};
