import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Device } from "../../../models/device";
import { fetchDevice } from "../../../services/devices-api";
import { SipSettingsCard } from "./SipSettingsCard";
import { CardContainer } from "../../../components/Card";
import Page from "../../../components/Page";
import { SimSettingsCard } from "./SimSettingsCard";
import { ContactInfoCard } from "./ContactInfoCard";
import { DeviceInfoCard } from "./DeviceInfoCard";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { getErrorCode } from "../../../services/clift-api-errors";
import { DeviceSettingsCard } from "./DeviceSettingsCard";
import { DeviceTechInfoCard } from "./DeviceTechInfoCard";
import { DeviceStateCard } from "./DeviceStateCard";
import { CarersCard } from "./carers/CarersCard";
import { NetworkInfoCard } from "./NetworkInfoCard";

export const LiftDetails = () => {
  const params = useParams();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [device, setDevice] = useState<Device>();

  const { t } = useTranslation();

  const getDeviceId = () => {
    const deviceID =
      params.deviceID !== undefined ? parseInt(params.deviceID) : undefined;
    return deviceID !== undefined && !isNaN(deviceID) ? deviceID : undefined;
  };

  useEffect(() => {
    const deviceId = getDeviceId();

    if (
      deviceId !== undefined &&
      !cliftState.sidepanelContent &&
      cliftState.currentTenant?.id
    ) {
      fetchDevice(cliftState.currentTenant?.id, deviceId)
        .then((dev) => {
          setDevice(dev);
        })
        .catch((err) => {
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("lift_list_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [params.deviceID, cliftState.sidepanelContent, cliftState.currentTenant]);

  if (!device) {
    return <progress></progress>;
  }

  return (
    <Page title={device.model} fromOriginalPage={cliftState.fromOriginalPage!}>
      {device && (
        <CardContainer>
          <DeviceInfoCard device={device} />
          <SimSettingsCard device={device} />
          {device.contactInfo && (
            <ContactInfoCard
              contactInfo={device.contactInfo}
              deviceId={device.id}
            />
          )}
          <CarersCard carers={device.carers} deviceId={device.id} />
          <SipSettingsCard device={device} />
          <DeviceSettingsCard device={device} />
          <DeviceTechInfoCard deviceId={getDeviceId()} />
          <NetworkInfoCard deviceId={getDeviceId()} />
          <DeviceStateCard deviceId={getDeviceId()} />
        </CardContainer>
      )}
    </Page>
  );
};
