import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import { CustomerFullResponse } from "../../models/customer";
import { AddLiftsForm } from "../lifts/AddLiftsForm";
import { addDeviceToCustomer } from "../../services/customers-api";
import { getErrorCode } from "../../services/clift-api-errors";
import { useState } from "react";
import { DeviceRegistrationItem } from "models/device";

export interface AddCustomerLiftsProps {
  editingCustomer: CustomerFullResponse;
}

export const AddCustomerLifts = ({
  editingCustomer,
}: AddCustomerLiftsProps) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
    setError(undefined);
  };

  const onAddLifts = (devices: DeviceRegistrationItem[]) => {
    addDeviceToCustomer(editingCustomer.tenantId, editingCustomer.id, devices)
      .then(() => {
        closeSidePanel();
      })
      .catch((err) => {
        setError(getErrorCode(err) as string);
      });
  };

  return (
    <SideModal
      title={t("add_lifts_title", { ns: "lift" })}
      onClose={closeSidePanel}
    >
      <AddLiftsForm
        error={error}
        initialDevices={editingCustomer.devices as DeviceRegistrationItem[]}
        onSubmit={onAddLifts}
        onClose={closeSidePanel}
        omitNickName={false}
      />
    </SideModal>
  );
};
