import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditFormValues } from "../../models/user";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { SideModalButtonBar } from "../../components/SideModalButtonBar";
import {
  StannahRole,
  RoleFormValues,
  EmployeeListEditAllowedRoles,
} from "../../models/role";
import FormField from "../../components/FormField";
import { RolesForm } from "./RolesForm";
import "./Employees.css";
import { isRoleForAction } from "../../hooks/useRoles";

export interface EditEmployeeFormProps {
  onSubmit: (roles: RoleFormValues[]) => Promise<void>;
  onClose: () => void;
  initialValues?: EditFormValues;
}

export const EditEmployeeForm = ({
  onSubmit,
  onClose,
  initialValues = {
    email: "",
    roles: [],
  },
}: EditEmployeeFormProps) => {
  const { t } = useTranslation();
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const [email] = useState(initialValues?.email);
  const [roles, setRoles] = useState<RoleFormValues[]>(
    initialValues?.roles || []
  );
  const employeeForm = useRef<HTMLFormElement>(null);
  const onHandleRoleChanged = (newRole: StannahRole, index: number) => {
    setChanged(true);
    const newRoles = roles.map((employeeRole, i) => {
      if (i === index) {
        return {
          ...employeeRole,
          role: newRole,
        };
      }
      return employeeRole;
    });
    setRoles(newRoles);
  };

  return (
    <div className="employee-form">
      <form ref={employeeForm}>
        <div className="employee-form-role-page">
          <div className="employee-form-creation-row">
            <div>
              <h3>{t("employee_info", { ns: "employee" })}</h3>
              <p>{t("employee_info_hint", { ns: "employee" })}</p>
              <h4>
                <b>{t("employee_info_note", { ns: "employee" })}</b>
              </h4>
            </div>
          </div>

          <div className="employee-form-creation-row">
            <FormField
              title={t("email", { ns: "customer" })}
              name="email"
              disabled={true}
              value={email ?? ""}
            />
          </div>
          <div>
            <RolesForm
              handleRoleChanged={onHandleRoleChanged}
              setChanged={setChanged}
              setRoles={setRoles}
              hasEditRights={isRoleForAction(EmployeeListEditAllowedRoles)}
              roles={roles}
            />
          </div>

          <div className="employee-form-errors"></div>
          <SideModalButtonBar
            onCancel={() => {
              if (hasChanged) {
                setCanceled(true);
              } else {
                onClose();
              }
            }}
            onSave={() => {
              onSubmit(roles);
            }}
            showSave
            saveDisabled={
              roles.length == 0 ||
              !isRoleForAction(EmployeeListEditAllowedRoles)
            }
          />
        </div>
      </form>
      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
