import { CliftReducerAction } from "../../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../../hooks/useCliftContext";
import { CarerRequest, UpdateCarersRequest } from "../../../../models/device";
import { DeviceRole } from "../../../../models/role";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import { getErrorCode } from "../../../../services/clift-api-errors";
import { updateCarers } from "../../../../services/devices-api";
import { emailRegex } from "../../../../app/constants";

export type EditCarersFormValues = {
  carers: CarerFormValues[];
};

export type CarerFormValues = {
  userId: number | null;
  email: string;
};

export function useEditCarers(deviceId: number, initialCarers: DeviceRole[]) {
  const carersToFormValues = (carers: DeviceRole[]) => {
    const formCarers: CarerFormValues[] = carers.map((carer) => {
      return {
        userId: carer.userId,
        email: carer.email,
      } as CarerFormValues;
    });
    return { carers: formCarers } as EditCarersFormValues;
  };

  const { cliftState, dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const formValues = carersToFormValues(initialCarers);
  const [carersForm, setCarersForm] =
    useState<EditCarersFormValues>(formValues);

  const formToRequest = (formValues: EditCarersFormValues) => {
    const newCarerList: CarerRequest[] = formValues.carers.map(
      (carerFormValue) => {
        return {
          userId: carerFormValue.userId,
          email: carerFormValue.email,
        } as CarerRequest;
      }
    );
    return {
      carers: newCarerList,
    } as UpdateCarersRequest;
  };

  const handleEditCarer = (index: number, value: string) => {
    const newCarersList = carersForm.carers.map((carer, i) => {
      if (i === index) return { userId: carer.userId, email: value };
      return carer;
    });
    setCarersForm({ carers: newCarersList });
  };

  const handleAddCarer = () => {
    const newList = carersForm.carers.concat({
      email: "",
      userId: null,
    } as CarerFormValues);
    setCarersForm({ carers: newList });
  };

  const handleDeleteCarer = (index: number) => {
    const newCarersList = carersForm.carers.filter((_, i) => {
      if (i === index) return false;
      return true;
    });
    setCarersForm({ carers: newCarersList });
  };

  const handleClose = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const invalidForm = (): boolean => {
    const invalidEmail = carersForm.carers.find(
      (value) => !emailRegex.test(value.email)
    );
    return !!invalidEmail;
  };
  const handleSave = () => {
    updateCarers(
      cliftState.currentTenant!.id,
      deviceId,
      formToRequest(carersForm)
    )
      .then(() => {
        handleClose();
        toast.success(t("carers_updated_successfully", { ns: "lift" }));
      })
      .catch((err) => {
        handleClose();
        toast.error(
          t("error") +
            t("update_carers_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            })
        );
      });
  };
  return {
    t,
    handleClose,
    handleAddCarer,
    handleEditCarer,
    handleDeleteCarer,
    handleSave,
    invalidForm,
    carersForm,
  };
}
