import { RoleFormValues } from "models/role";
import { EmployeeListItem, EmployeeResponseFull } from "../models/user";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";

export const fetchEmployee = (
  tenantId: number,
  userId: number
): Promise<EmployeeResponseFull> =>
  fetch(`/api/tenant/${tenantId}/employees/${userId}`, {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchEmployees = (tenantId: number): Promise<EmployeeListItem[]> =>
  fetch(`/api/tenant/${tenantId}/employees`, {
    method: "POST",
    body: JSON.stringify({
      pageSize: 100,
      pageNumber: 0,
      search: "",
      sort: { column: "NAME", direction: "ASC" },
    }),
  })
    .then(parseResponse)
    .then((res) => res.content)
    .catch((e) => handleError(e));

export const inviteEmployees = (
  tenantId: number,
  emails: string[],
  roles: RoleFormValues[]
) =>
  fetch(`/api/tenant/${tenantId}/employees/invite`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      emails: emails,
      roles,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const editEmployee = (
  tenantId: number,
  userId: number,
  roles: RoleFormValues[]
) =>
  fetch(`/api/tenant/${tenantId}/employees/${userId}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      roles,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const deleteUser = (userId: number, tenantId: number): Promise<void> =>
  fetch(`/api/tenant/${tenantId}/employees/${userId}`, {
    method: "DELETE",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
