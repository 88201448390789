import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import { inviteEmployees } from "../../services/employees-api";
import { getErrorCode } from "../../services/clift-api-errors";
import { RoleFormValues } from "models/role";
import { InviteEmployeesForm } from "./InviteEmployeesForm";

export const InviteEmployees = () => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (loading) return <progress></progress>;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onInviteEmployees = (emails: string[], roles: RoleFormValues[]) => {
    if (!cliftState.currentTenant) throw new Error("No current tenant");

    setLoading(true);

    return inviteEmployees(cliftState.currentTenant.id, emails, roles)
      .then(() => {
        setLoading(false);
        closeSidePanel();
      })
      .catch((err: Error) => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("invite_employee_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  return (
    <SideModal
      title={t("invite_new_employee", { ns: "employee" })}
      pageCount={1}
      onClose={closeSidePanel}
    >
      <InviteEmployeesForm
        onSubmit={onInviteEmployees}
        onClose={closeSidePanel}
      />
    </SideModal>
  );
};
