import { ContactCommonResponse } from "../models/contact";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";

export const fetchContacts = (
  tenantId: number,
  customerId: number
): Promise<ContactCommonResponse[]> =>
  fetch(`/api/tenant/${tenantId}/customers/${customerId}/contacts/`, {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchTenantsContacts = (
  tenantId: number
): Promise<ContactCommonResponse[]> =>
  fetch(`/api/tenant/${tenantId}/contacts/`, {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
