import FormField from "../../components/FormField";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeviceRegistrationItem } from "../../models/device";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { AddLiftsConfirm } from "./AddLiftsConfirm";
import "./AddLiftsForm.css";

export interface AddLiftsFormProps {
  error: string | undefined;
  initialDevices?: DeviceRegistrationItem[];
  omitNickName: boolean;
  onSubmit: (devices: DeviceRegistrationItem[]) => void;
  onClose: () => void;
}

export const AddLiftsForm = ({
  error,
  initialDevices = [],
  omitNickName,
  onSubmit,
  onClose,
}: AddLiftsFormProps) => {
  const { t } = useTranslation();
  const [devices, setDevices] =
    useState<DeviceRegistrationItem[]>(initialDevices);
  const [newDevices, setNewDevices] = useState<DeviceRegistrationItem[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const addLiftForm = useRef<HTMLFormElement>(null);

  const getErrorText = (errorCode: string | number) => {
    if (errorCode === 404) {
      return t("device_not_found", { ns: "lift" });
    } else {
      return t("adding_device_failed", {
        ns: "lift",
        code: errorCode,
      });
    }
  };

  const onFieldChanged = (
    name: string,
    value: string | number,
    index: number
  ) => {
    setNewDevices(
      newDevices.map((val, i) => {
        if (i === index) {
          return {
            ...val,
            [name]: value,
          };
        }
        return val;
      })
    );
  };

  return (
    <div className="add-lifts-form">
      <form
        ref={addLiftForm}
        onSubmit={(e) => {
          e.preventDefault();
          if (newDevices.length) {
            setShowConfirm(true);
          } else {
            onSubmit(devices);
          }
        }}
      >
        <div className="add-lifts-form-page">
          <div className="add-lifts-form-creation-row">
            <div>
              <h3>{t("add_lifts_subtilte", { ns: "lift" })}</h3>
              <h4>{t("add_lifts_hint", { ns: "lift" })}</h4>
              <div>{t("add_lifts_note", { ns: "lift" })}</div>
            </div>

            <button
              className="button"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setNewDevices([
                  {
                    serialNumber: "",
                    nickname: "",
                  },
                  ...newDevices,
                ]);
                setChanged(true);
              }}
            >
              <PlusIcon />
              {t("add_new_lift_btn", { ns: "lift" })}
            </button>
          </div>

          {newDevices.concat(devices).map((device, i) => (
            <div key={i} className="add-lifts-form-creation-input-row">
              <FormField
                title={t("x06_serial_number", { ns: "lift" })}
                name="serialNumber"
                disabled={i >= newDevices.length}
                value={device.serialNumber}
                onValueChanged={(name, value) => onFieldChanged(name, value, i)}
              />
              {!omitNickName && (
                <FormField
                  title={t("lift_name_or_model", { ns: "lift" })}
                  disabled={i >= newDevices.length}
                  name="nickname"
                  value={device.nickname}
                  onValueChanged={(name, value) =>
                    onFieldChanged(name, value, i)
                  }
                />
              )}
              <button
                className="add-lifts-form-delete-btn"
                title={t("delete", { ns: "translation" })}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (i < newDevices.length) {
                    setNewDevices(newDevices.toSpliced(i, 1));
                  } else {
                    setDevices(devices.toSpliced(i - newDevices.length, 1));
                  }
                  setChanged(true);
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <div className="add-lifts-form-errors">
            {error && getErrorText(error)}
          </div>

          <div className="add-lifts-form-button-panel">
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              className="button"
              id="submit_button"
              disabled={!hasChanged}
            >
              {t("save", { ns: "translation" })}
            </button>
          </div>

          <AddLiftsConfirm
            isOpen={showConfirm}
            devices={newDevices}
            onConfirm={() => {
              setShowConfirm(false);
              onSubmit(newDevices.concat(devices));
            }}
            onCancel={() => {
              setShowConfirm(false);
            }}
          />
        </div>
      </form>
    </div>
  );
};
